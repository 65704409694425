import $ from 'jquery';
import 'slick-carousel';

export default () => {



  $(() => {
    
    if ($('.headerHero__slider').length) {
      const initHeaderHeroSlider = () => {
        $('.headerHero__slider').slick({
          arrows: false,
          dots: true,
          fade: true,
          speed: 1500
        });
      }
      const headerHeroSliderImages: NodeListOf<HTMLImageElement> = document.querySelectorAll('.headerHero img');
      let countLoadHeaderHeroSliderImages = 0;
      headerHeroSliderImages.forEach(headerHeroSliderImage => {
        if (headerHeroSliderImage.complete) {
          countLoadHeaderHeroSliderImages++;
        }
        headerHeroSliderImage.addEventListener('load', () => {
          countLoadHeaderHeroSliderImages++;
          if (countLoadHeaderHeroSliderImages == headerHeroSliderImages.length) {
            initHeaderHeroSlider();
            $('.headerHero').addClass('is-load');
          }
        });
      });
      if (countLoadHeaderHeroSliderImages == headerHeroSliderImages.length) {
        initHeaderHeroSlider();
        $('.headerHero').addClass('is-load');
      }
    }

  });

}